.app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.siteHeader {
  color: white;
  text-align: center;
  font-family: Helvetica;
  display: inline-block;
}

.App-link {
  color: #61dafb;
}
